import React, { useState } from 'react';
import Tabs from '../components/Tabs';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { tabs } from '../config.js';

const Sales = () => {
  const salesTabs = tabs.filter(tab => tab.page === 'sales')
  const firstSalesTabId = salesTabs.find(tab => tab.page === 'sales').id;
  
  const [activeTab, setActiveTab] = useState(firstSalesTabId);
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
      <div className="flex rounded-xl justify-center h-full my-2 mx-6">
          {salesTabs.length === 1 ? null : 
            <div className="flex flex-col bg-transparent pr-3">
              <Tabs tabs={salesTabs} activeTab={activeTab} onTabChange={handleTabChange} iconClasses="h-[24px] w-[24px]" />
            </div>
          }
          <div className="bg-white flex w-full h-full rounded-lg justify-center items-center p-2">
            {salesTabs.map((tab) => (
              activeTab === tab.id && (
                <iframe
                  key={tab.id}
                  src={tab.src}
                  title={tab.label}
                  className="w-full h-full border rounded-lg"
                  frameBorder="0"
                />
              )
            ))}
          </div>
        <ReactTooltip
        id="sales-in-out-stock-tooltip"
        place="right"
        content="Sales-In-Out-Stock"
        />
        <ReactTooltip
        id="sales-in-out-stock-budget-tooltip"
        place="right"
        content="Sales-In-Out-Stock-Budget"
        />
      </div>
  );
};

export default Sales;
