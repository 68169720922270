import { faArrowDownUpAcrossLine, faFileInvoiceDollar, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';

export const tabs = [
    { 
        id: 1, 
        src: 'https://app.powerbi.com/reportEmbed?reportId=35f24d97-92e3-4a64-8e6c-030c48d592d0&autoAuth=true&ctid=c0a3f211-c7b3-495a-a862-5454912f27cd', 
        tooltipId: "sales-in-out-stock-tooltip", 
        icon: faArrowDownUpAcrossLine ,
        page: 'sales'
    },
    { 
        id: 2, 
        src: 'https://app.powerbi.com/reportEmbed?reportId=50dfa870-5523-474a-a1c7-34b803ed3c10&autoAuth=true&ctid=c0a3f211-c7b3-495a-a862-5454912f27cd', 
        tooltipId: "sales-in-out-stock-budget-tooltip", 
        icon: faFileInvoiceDollar,
        page: 'sales'
    },
    {
        id: 3,
        src: 'https://app.powerbi.com/reportEmbed?reportId=29b15c92-e761-4792-bbba-6e721e1f945d&autoAuth=true&ctid=a72dda32-ee80-4da8-a3ac-ec0e9e41a50a',
        tooltipId: 'protocol-tooltip',
        icon: faFileCircleCheck,
        page: 'protocol'
    },
  ];