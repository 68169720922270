import React from 'react';
import logo from '../resources/SoftLab logo.png';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className='flex flex-row justify-center before:items-center mb-3 mt-2 px-6 text-mainColor1'>
        <div className='flex items-center footer'>
          Copyright © 2021 - {year}
          <a href="http://www.softlab.rs/" target='_blank' rel="noreferrer">
            <img src={logo} alt="SoftLab logo" className='mx-2' />
          </a>
          All rights reserved.
        </div>
    </div>
  );
};

export default Footer;
