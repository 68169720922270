import { Route, Routes } from 'react-router-dom';
import SalesPage from './pages/SalesPage.jsx';
import ProtocolPage from './pages/ProtocolPage.jsx';


const App = () => {
  return (
    <div className="w-full h-full">
        <Routes>
         <Route path='/sales' element={<SalesPage/>} />
         <Route path='/protocol' element={<ProtocolPage/>} />
       </Routes>
    </div>
  );
}

export default App;
