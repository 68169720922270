import React from 'react';
import PharmaSwissLogo from '../resources/PharmaSwissLogo.png';
import BISTROLogo from '../resources/bistro logo.png';

const Header = () => {

  return (
    <div className='flex flex-row justify-between items-center my-2 px-6 text-mainColor1 header'>
        <img src={BISTROLogo} alt="BISTRO Logo" />
        <a href="https://pharmaswiss.rs/" target='_blank' rel="noreferrer">
          <img src={PharmaSwissLogo} alt="PharmaSwissLogo" />
        </a>
        <div className='spacer-header w-24'></div>
    </div>
  );
};

export default Header;
