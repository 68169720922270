import React from 'react';
import Header from '../features/Header';
import Footer from '../features/Footer';
import Protocol from '../features/Protocol';

const ProtocolPage = () => {
  return (
    <div className='flex flex-col h-full w-full bg-splash-screen '>
      <Header />
      <Protocol />
      <Footer />
    </div>
  );
};

export default ProtocolPage;
