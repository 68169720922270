import React from 'react';
import Header from '../features/Header';
import Footer from '../features/Footer';
import Sales from '../features/Sales';

const SalesPage = () => {
  return (
    <div className='flex flex-col h-full w-full bg-splash-screen '>
      <Header />
      <Sales />
      <Footer />
    </div>
  );
};

export default SalesPage;
