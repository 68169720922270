import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ buttonKey, label, classes, tooltipId, onClick, icon, iconClasses }) => {
  return (
    <button key={buttonKey} className={classes} onClick={onClick} data-tooltip-id={tooltipId}>
      {icon && <FontAwesomeIcon className={iconClasses} icon={icon} />}
      {label}
    </button>
  );
};

export default Button;
