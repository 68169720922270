import React from 'react';
import Button from './Button';

const Tabs = ({ tabs, activeTab, buttonClasses, onTabChange, icon, iconClasses }) => {
  return (
    <div className="flex">
      <nav className="flex flex-col space-y-4 mt-4">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            label={tab.label}
            tooltipId={tab.tooltipId}
            classes={`${buttonClasses} p-2 text-mainColor2 rounded-xl ${activeTab === tab.id ? 'bg-gray-100' : 'opacity-30 bg-gray-100 hover:opacity-80 hover:bg-gray-50'}` }
            iconClasses={iconClasses}
            onClick={() => onTabChange(tab.id)}
            icon={tab.icon ? tab.icon : icon}
          />
        ))}
      </nav>
    </div>
  );
};

export default Tabs;
